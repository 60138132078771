import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/index',
    name: 'index',
    component: () => import('../views/index.vue')
  },
  {
    path: '/public',
    name: 'public',
    component: () => import('../views/Public.vue')
  },
  {
    path: '/floor',
    name: 'floor',
    component: () => import('../views/Floor.vue')
  },
  {
    path: '/ice',
    name: 'ice',
    component: () => import('../views/Ice.vue')
  },
  {
    path: '/solar',
    name: 'solar',
    component: () => import('../views/Solar.vue')
  },
  {
    path: '/download',
    name: 'download',
    component: () => import('../views/Download.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
