/* eslint-disable */
const dbDataHandle = (num, to = 2) => {
    try {
      if (isNaN(num) || num === -Infinity || num === Infinity || num === null || num === undefined || num < 0) return 0
  
      let strNum = (typeof num === 'string') ? num : num.toString()
      let result = ''
      let i = 0
      let j = ''
  
      // 10.15
      for (i; i < strNum.length; i++) {
        if (strNum[i] !== '.') {
          result += strNum[i]
        } else {
          result += (to === 0) ? '' : strNum[i]
          j = i
        }
  
        if (i === (j + to)) break
      }
  
      return result * 1
    } catch (err) {
      console.log(err)
      return 0
    }
  }

export { dbDataHandle }