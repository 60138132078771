import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import Highcharts from 'highcharts'
import VueSweetalert2 from 'vue-sweetalert2'
import VueHighcharts from 'vue-highcharts'
import VueCookies from 'vue-cookies'
import 'sweetalert2/dist/sweetalert2.min.css'
import interceptors from './http'
Vue.use(VueAxios, axios)
Vue.use(VueSweetalert2)
Vue.use(VueHighcharts)
Vue.use(Highcharts)
Vue.use(VueCookies)
Vue.use(interceptors)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
