<template>
  <div id="app">
    <!-- <div id="nav">
      <router-link to="/">Login</router-link>
      <router-link to="/chart">chart</router-link>
    </div>
    {{getTimenow}}
      <div style="display:flex; align-items: center;justify-content: center; gap:1em;">
        <img :src="weather[1]" style="width:60px">
        <h1>{{weather[0]}} ℃</h1>
        <h1>{{weather[2]}}</h1>
        <h1>濕度{{weather[3]}}%</h1>
        <h1>降雨率{{pop}}%</h1>
      </div> -->
    <router-view/>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'
export default {
  data () {
    return {
    }
  },
  methods: {
    ...mapActions([
      'weatherAPI'
    ]),
    memberCheck () {
      const memeber = JSON.parse(sessionStorage.getItem('login')) ? 1 : 0
      if (memeber && this.$router.history.current.name === 'login') {
        this.$router.push({ path: '/index' }).catch(err => console.log('router push has error', err))
      } else if (!memeber) {
        this.$router.push({ path: '/' }).catch(err => console.log('router push has error', err))
      }
    }
  },
  mounted () {
    this.memberCheck()
    this.weatherAPI()
  },
  computed: {
    ...mapState([
      'weather',
      'pop'
    ])
  }
}
</script>
<style>
</style>
